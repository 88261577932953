import { useTranslation } from "@i18n";
import stl from "./MobileFooter.module.css";
import { CDN_LINK } from "@globalConfig";
import useIsFirstInteraction from "@hooks/useIsFirstInteraction";
import useHelpers from "@hooks/useHelpers";

const MobileFooter = () => {
  const { t } = useTranslation();
  const isFirstInteraction = useIsFirstInteraction();
  const { createUrl } = useHelpers();
  return (
    <>
      <div className="container">
        <div className={stl.upperSection}>
          {isFirstInteraction && (
            <img
              src={CDN_LINK + "/assets/images/safety.svg"}
              alt="i"
              width={50}
              height={50}
            />
          )}
          <p>
            {t(
              "شركة موقع سيارة للتجارة شركة موثقة لدى وزارة التجارة والاستثمار وبدعم من شركة علم, بسجل تجاري رقم 1010538980",
              {
                ns: "web_footer",
              }
            )}
          </p>
          {isFirstInteraction && (
              <div className={stl.footerWithVat}>
                <a
                    className={stl.vatLink}
                    href={
                        CDN_LINK + "/assets/bundles/GAZT_VAT_Certificate.pdf"
                    }
                    rel="noopener noreferrer" target="_blank">
                  <img
                      width="30px"
                      height={"38px"}
                      src={CDN_LINK + "/assets/images/Vat.svg"}
                      alt="vat icon"
                  />
                </a>
                <img
                    src={CDN_LINK + "/assets/images/saudi_elm.svg"}
                    alt="saudi_elm icon"
                    width={"262px"}
                    height={"44px"}
                />
              </div>
          )}
        </div>
        <div className={stl.lowerSection}>
          <a href={createUrl("/")} aria-label="Syarah logo">
            {isFirstInteraction && (
              <img
                style={{ width: "83px" }}
                src={CDN_LINK + "/assets/images/logogreyN.svg"}
                alt="Syarah logo"
                width={83}
                height={38}
                aria-label="Syarah logo"
              />
            )}
          </a>
          <p className="interActiveGray">
            {t(" شركة سعوديه بسجل تجاري 1010538980 مصدره الرياض", {
              ns: "web_footer",
            })}
          </p>
          <div className={stl.paymentMethods}>
            {isFirstInteraction && (
              <img
                src={CDN_LINK+"/assets/bundles/greenArmor.svg"}
                alt="payments"
                width={15}
                height={17}
              />
            )}
            <span>
              {t("طرق دفع إلكترونية آمنة", {
                ns: "web_footer",
              })}
            </span>
            <ul>
              <li>
                {isFirstInteraction && (
                  <img
                    src={CDN_LINK + "/assets/bundles/Mada.svg"}
                    alt=""
                    width={43}
                    height={26}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src="/assets/bundles/Visadark.svg"
                    alt=""
                    width={43}
                    height={26}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src="/assets/bundles/mc.svg"
                    alt=""
                    width={43}
                    height={26}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src={CDN_LINK + "/assets/images/apple_pay.svg"}
                    alt=""
                    width={42}
                    height={24}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src={CDN_LINK + "/assets/images/g_pay.svg"}
                    alt=""
                    width={42}
                    height={24}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src="/assets/bundles/Sadad.svg"
                    alt=""
                    width={43}
                    height={26}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src={CDN_LINK + "/assets/bundles/paypalstacked.svg"}
                    alt=""
                    width={43}
                    height={26}
                  />
                )}
              </li>
              <li>
                {isFirstInteraction && (
                  <img
                    src={CDN_LINK + "/assets/bundles/BankTransfer.svg"}
                    alt=""
                    width={43}
                    height={26}
                  />
                )}
              </li>
            </ul>
          </div>
          <p className="interActiveGray">
            {t("جميع الحقوق محفوظة لشركة موقع سيارة المحدودة © 2022", {
              ns: "web_footer",
            })}
          </p>
          <div className={`${stl.links}`}>
            <a
              rel="noopener noreferrer"
              href={createUrl("/terms-landing")}
              className="interActiveGray"
            >
              {t("الأحكام والشروط", {
                ns: "web_footer",
              })}{" "}
            </a>{" "}
            |
            <a
              rel="noopener noreferrer"
              href={createUrl("/privacy-policy")}
              className="interActiveGray"
            >
              {t("سياسة الخصوصية", {
                ns: "web_footer",
              })}{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileFooter;
